import React, { useEffect, useState } from 'react';
import ChartsPage from './Charts';
import NavBar from '../HomePage/NavBar_home';
import './analysis.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { fetchAssessmentData, fetchCombinedReport } from './analysis_api'; 

const MoodAnalysis = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [days, setDays] = useState('5');
  const [assessmentData, setAssessmentData] = useState(null); // Initialize as null
  const [combinedReport, setCombinedReport] = useState(null); // Initialize as null

  const handleDaysChange = (event) => {
    setDays(event.target.value);
  };

  useEffect(() => {
    const userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
    const user = userinfo.username;
    // console.log(userinfo);

    const fetchData = async () => {
      setLoading(true);
      try {
        const assessmentResult = await fetchAssessmentData(user);
        if (assessmentResult.success) {
          setAssessmentData(assessmentResult.data);
          // console.log('Assessment data fetched and filtered successfully', assessmentResult.data);
        } else {
          console.error('Error fetching assessment data:', assessmentResult.error);
        }

        const reportResult = await fetchCombinedReport(user, days);
        if (reportResult.success) {
          setCombinedReport(reportResult.data);
        } else {
          setError('Error fetching combined report: ' + reportResult.error);
        }
      } catch (error) {
        setError('Error fetching data: ' + error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [days]); 

  if (loading) {
    return (
      <div className='main'>
        <NavBar />
        <div className="dashboard">
          <div className="loading-screen">
            <div className="loading-circle-home"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='main'>
      <NavBar />
      <div className="dashboard">
        {error && <p>{error}</p>}
        <div className="title-and-form-container">
          <label className='inputDays'>
            <CalendarMonthIcon sx={{marginTop:"2px",marginRight:"5px"}}/> Days
            <select className="label-days" value={days} onChange={handleDaysChange}>
              <option value="5">5</option>
              <option value="7">7</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="60">60</option>
              <option value="90">90</option>
            </select>
          </label>
          <div className="sentiment-title">
          Emotional Wellness Trends
          </div>
        </div>
  
        {assessmentData && combinedReport ? (
          <ChartsPage assessmentData={assessmentData} combinedReport={combinedReport} />
        ) : (
          <p>Loading charts...</p> 
        )}
      </div>
      <div className="home-footer">
  <p>
    BAIA: Your AI-Powered Emotional Support Assistant{' '}
    <a
      href="https://www.baia.ai/terms-and-condition/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Terms
    </a>,{' '}
    <a
      href="https://www.baia.ai/disclaimer/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Disclaimer
    </a>{' '}
    and{' '}
    <a
      href="https://www.baia.ai/privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Privacy Policy
    </a> || Version: Beta-4.0
  </p>
</div>

    </div>
  );
};

export default MoodAnalysis;
