import React, { useState, useEffect } from 'react';

// Ensure the BASE_URL is defined correctly
import { BASE_URL } from "../config";

const ImageRenderer = React.memo(({ user_name, content }) => {
    const [imageSrc, setImageSrc] = useState(() => {
      return sessionStorage.getItem(`${user_name}_chatimage`) || null; // Initialize with cached image if available
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
  
    useEffect(() => {
      const urlRegex = /https?:\/\/[^\s]+/g;
      const urls = content.match(urlRegex);
  
      if (!urls || urls.length === 0) {
        setImageSrc(null); // Reset if no URLs found
        setLoading(false);
        setError(false);
        return; // Early return if no URLs
      }
  
      const fetchAndRenderImage = (userName) => {
        const postfixedUserName = `${userName}_chatimage`;
        setLoading(true);
  
        fetch(`${BASE_URL}/fetch_image?user_name=${postfixedUserName}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              sessionStorage.setItem(`${user_name}_chatimage`, data.response); // Cache the image
              setImageSrc(data.response); // Update state with fetched image
              setError(false); // Reset error state
            } else {
              console.error("No image found for this identifier");
              setError(true);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      };
  
      // Force a new fetch and render if the content has changed
      fetchAndRenderImage(user_name);
    }, [user_name, content]); // Include content as a dependency to re-fetch when it changes
  
    if (loading) return <p>Loading image...</p>; // Show loading text
    if (error) return <p>Error loading image. Please check the image identifier or API.</p>; // Show error message
    if (!imageSrc) return null; // Return null if no image source
  
    return (
      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <img
          src={imageSrc}
          alt="Uploaded by user"
          style={{
            maxWidth: "50%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          onError={(e) => {
            console.log("Image failed to load:", imageSrc);
            e.target.style.display = "none"; // Hide the image if it fails to load
          }}
        />
      </div>
    );
  });
  
  
export default ImageRenderer;



// const ImageRenderer = React.memo(({ user_name, content }) => {
//   const [imageSrc, setImageSrc] = useState(() => {
//     return sessionStorage.getItem(user_name) || null;
//   });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(false);

//   useEffect(() => {
//     const urlRegex = /https?:\/\/[^\s]+/g;
//     const urls = content.match(urlRegex);

//     if (!urls || urls.length === 0) {
//       setImageSrc(null);
//       setLoading(false);
//       setError(false);
//       return;
//     }

//     const fetchAndRenderImage = (userName) => {
//       const postfixedUserName = `${userName}_chatimage`;

//       setLoading(true);

//       fetch(`${BASE_URL}/fetch_image?user_name=${postfixedUserName}`)
//         .then((response) => response.json())
//         .then((data) => {
//           if (data.success) {
//             sessionStorage.setItem(userName, data.response);
//             setImageSrc(data.response);
//             setError(false);
//           } else {
//             console.error("No image found for this identifier");
//             setError(true);
//           }
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//           setError(true);
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     };

//     if (!imageSrc) {
//       fetchAndRenderImage(user_name);
//     }
//   }, [user_name, content, imageSrc]);

//   if (!imageSrc) return null;
//   if (loading) return <p>Loading image...</p>;
//   if (error) return <p>Error loading image. Please check the image identifier or API.</p>;

//   return (
//     <div style={{ textAlign: 'right', marginTop: '10px' }}>
//       <img
//         src={imageSrc}
//         alt="Uploaded by user"
//         style={{
//           maxWidth: "50%",
//           borderRadius: "8px",
//           marginTop: "10px",
//         }}
//         onError={(e) => {
//           console.log("Image failed to load:", imageSrc);
//           e.target.style.display = "none";
//         }}
//       />
//     </div>
//   );
// });

// export default ImageRenderer; // Ensure to export the component correctly
