

// import React, { useState, useEffect } from 'react';
// import { Box, Typography, Card, CardContent, IconButton, Button } from '@mui/material';
// import { useParams, useNavigate } from 'react-router-dom';
// import { ArrowBack, ArrowForward, BorderClear } from '@mui/icons-material';
// import { useAssessment } from '../context/AssessmentContext';
// import NavBar from './Ass_Nav';
// import ProgressBar from './CustomProgressBar'; // Adjust the path as per your file structure
// import "./Ass_detail.css";
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import { BASE_URL } from '../config'; // Import the base URL
// import { getSessionData } from '../utils/session';

// const AssessmentDetail = () => {
//   const { assessmentType } = useParams();
//   const [assessmentQuestions, setAssessmentQuestions] = useState([]);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [answers, setAnswers] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const [submissionStatus, setSubmissionStatus] = useState(null);
//   const navigate = useNavigate();
//   const { setAssessmentResult } = useAssessment();
//   const sessionData = getSessionData();

//   const user_name = sessionData?.username;  useEffect(() => {
//     const fetchAssessmentQuestions = () => {
//       fetch(`${BASE_URL}/api/assessment_view_mb`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           assessment_type: assessmentType,
//         }),
//       })
//         .then(response => response.json())
//         .then(data => {
//           if (data.status === 'Success.') {
//             // console.log("assessment View",data.response);
//             const transformedData = Object.entries(data.response).map(([question, ratings]) => {
//               let parsedRatings;
              
//               try {
//                 // Try parsing the ratings as JSON
//                 parsedRatings = JSON.parse(ratings);
//               } catch (error) {
//                 // If parsing fails, assume the ratings are a simple string and split by commas
//                 parsedRatings = ratings.replace(/[\[\]']/g, '').split(',').map(rating => rating.trim());
//               }
              
//               return {
//                 question,
//                 ratings: parsedRatings,
//               };
//             });
            
//             setAssessmentQuestions(transformedData);
//           } else {
//             console.error('Failed to fetch assessment questions:', data.process_status);
//           }
//           setIsLoading(false);
//         })
//         .catch(error => {
//           console.error('Error fetching assessment questions:', error);
//           setIsLoading(false);
//         });
//     };

//     setIsLoading(true);
//     fetchAssessmentQuestions();
//     setAnswers({});
//   }, [assessmentType]);

//   const handleAnswerChange = (question, rating) => {
//     setAnswers((prevAnswers) => {
//       // Check if the rating is a number (integer) or a string
//       const parsedRating =  rating;
  
//       // Update answers with the new rating for the current question
//       return { ...prevAnswers, [question]: parsedRating };
//     });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//   // Get current date and time
//   const currentDateTime = new Date().toISOString();

//     // const user_name = "laxmi";
//     const output = {
//       assessment_type:assessmentType,
//       user_name:user_name,
//       user_assessment_db_input: answers,
//       timestamp:currentDateTime
//     };

    
//     // console.log('Submitted output:', output);
//     setAssessmentResult(output);

//     fetch(`${BASE_URL}/api/assessment_update_mb`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(output),
//     })
//       .then(response => response.json())
//       .then(data => {
//         console.log('Assessment update response:', data);
//         if (data.status === 'Success.') {
//           setSubmissionStatus('Success');
//           handleViewScore();  // Navigate to the score view on successful submission
//         } else {
//           setSubmissionStatus('Failed');
//         }
//       })
//       .catch(error => {
//         console.error('Error updating assessment:', error);
//         setSubmissionStatus('Failed');
//       });
//   };

//   const handleViewScore = () => {
//     navigate('/analysis');
//   };

//   const handleNextQuestion = () => {
//     setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, assessmentQuestions.length - 1));
//   };

//   const handlePreviousQuestion = () => {
//     setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
//   };

//   if (isLoading) {
//     return <ProgressBar bgcolor="#6a1b9a" completed={0} />; // Initial progress state or loading state
//   }

//   if (assessmentQuestions.length === 0) {
//     return <Typography>No questions available.</Typography>;
//   }

//   const currentQuestion = assessmentQuestions[currentQuestionIndex];
//   const progress = (currentQuestionIndex + 1) / assessmentQuestions.length * 100;
// // Define an array of objects with colors and texts
// const ratingDetails = [
//   { color: "blue"  },
//   { color: 'green' },
//   { color: '#FFAE42' },
//   { color: '#FF8F00' },
//   { color: 'red' }
// ];

//   return (
//     <Box className="Parent">
//       <Button
//         variant="contained"
//         color="secondary"
//         className="EndButton"
//         onClick={() => navigate('/assessment')}
//       >
//         End
//       </Button>
//       <Box className="Child">
//         <Box className="progress-bar">
//         <ProgressBar bgcolor="#6a1b9a" completed={progress} />
//         </Box>
     

//       <Box className="CardContainer">
//       {currentQuestion ? (
//   <Card className="Card">
//     <CardContent>
//       <Typography 
//         variant="h7"  
//         className='text-p'
//         sx={{ marginBottom: 4, marginTop: 0, fontFamily: 'robot-thin' }}
//       >
//         {currentQuestion.question}
//       </Typography>
//       {currentQuestion.ratings.map((rating, index) => {
//         const isSelected = String(answers[currentQuestion.question]) === String(rating);
//         return (
//           <Box key={rating} sx={{ mt: 1 }}>
//             <Button
//             className='rating-btn'
//               variant={isSelected ? 'contained' : 'outlined'}
//               onClick={() => handleAnswerChange(currentQuestion.question, rating)}
//               sx={{
//                 width: '100%',
//                 color: 'black',
//                 fontWeight: 'bold',
//                 borderColor: 'rgb(58, 6, 107)',
//                 '&:hover': {
//                   backgroundColor: 'rgb(58, 6, 107,0.3)',
//                   borderColor: 'rgb(58, 6, 107)',
//                 },
//               }}
//             >
//               {rating}
//             </Button>
//           </Box>
//         );
//       })}
//             </CardContent>
//           </Card>
//         ) : (
//           <Typography>No question to display</Typography>
//         )}

//         <Box className="ButtonsContainer">
//           <IconButton onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
//             <ArrowBackIosIcon color='black' fontSize='large'/>
//           </IconButton>

//           {currentQuestionIndex === assessmentQuestions.length - 1 ? (
//             <Button variant="contained" onClick={handleSubmit} className='submitbutton'>
//               Submit
//             </Button>
//           ) : (
//             <IconButton onClick={handleNextQuestion} disabled={currentQuestionIndex === assessmentQuestions.length - 1}>
//               <ArrowForwardIosIcon color='black' fontSize='large'/>
//             </IconButton>
//           )}
//         </Box>

//         {submissionStatus && (
//           <Box
//             sx={{
//               mt: 2,
//               p: 2,
//               borderRadius: 2,
//               backgroundColor: submissionStatus === 'Success' ? '#d4edda' : '#f8d7da',
//               color: submissionStatus === 'Success' ? '#155724' : '#721c24',
//               border: `1px solid ${submissionStatus === 'Success' ? '#c3e6cb' : '#f5c6cb'}`,
//               maxWidth: 600,
//               width: '100%',
//               textAlign: 'center',
//               margin: 'auto' /* Center the status box horizontally */
//             }}
//           >
//             <Typography>
//               {submissionStatus === 'Success' ? 'Test submitted successfully!' : 'There was an error submitting the test.'}
//             </Typography>
//           </Box>
//         )}
//       </Box>
//       </Box>
//     </Box>
//   );
// };

// export default AssessmentDetail;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, IconButton, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { useAssessment } from '../context/AssessmentContext';
import NavBar from './Ass_Nav';
import ProgressBar from './CustomProgressBar'; // Adjust the path as per your file structure
import "./Ass_detail.css";
import { BASE_URL } from '../config'; // Import the base URL
import { getSessionData } from '../utils/session';

const AssessmentDetail = () => {
  const { assessmentType } = useParams();
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const navigate = useNavigate();
  const { setAssessmentResult } = useAssessment();
  const sessionData = getSessionData();

  const user_name = sessionData?.username;

  useEffect(() => {
    const fetchAssessmentQuestions = () => {
      fetch(`${BASE_URL}/api/assessment_view_mb`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          assessment_type: assessmentType,
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'Success.') {
            const transformedData = Object.entries(data.response).map(([question, ratings]) => {
              let parsedRatings;
              
              try {
                parsedRatings = JSON.parse(ratings);
              } catch (error) {
                parsedRatings = ratings.replace(/[\[\]']/g, '').split(',').map(rating => rating.trim());
              }
              
              return {
                question,
                ratings: parsedRatings,
              };
            });
            
            setAssessmentQuestions(transformedData);
          } else {
            console.error('Failed to fetch assessment questions:', data.process_status);
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching assessment questions:', error);
          setIsLoading(false);
        });
    };

    setIsLoading(true);
    fetchAssessmentQuestions();
    setAnswers({});
  }, [assessmentType]);

  const handleAnswerChange = (question, rating) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers, [question]: rating };

      // Automatically move to the next question if it's not the last one
      if (currentQuestionIndex < assessmentQuestions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }

      return updatedAnswers;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const currentDateTime = new Date().toISOString();

    const output = {
      assessment_type: assessmentType,
      user_name: user_name,
      user_assessment_db_input: answers,
      timestamp: currentDateTime
    };

    setAssessmentResult(output);

    fetch(`${BASE_URL}/api/assessment_update_mb`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(output),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'Success.') {
          setSubmissionStatus('Success');
          handleViewScore();  // Navigate to the score view on successful submission
        } else {
          setSubmissionStatus('Failed');
        }
      })
      .catch(error => {
        console.error('Error updating assessment:', error);
        setSubmissionStatus('Failed');
      });
  };

  const handleViewScore = () => {
    navigate('/analysis');
  };

  if (isLoading) {
    return <ProgressBar bgcolor="#6a1b9a" completed={0} />;
  }

  if (assessmentQuestions.length === 0) {
    return <Typography>No questions available.</Typography>;
  }

  const currentQuestion = assessmentQuestions[currentQuestionIndex];
  const progress = (currentQuestionIndex + 1) / assessmentQuestions.length * 100;

  return (
    <Box className="Parent">
      <Button
        variant="contained"
        color="secondary"
        className="EndButton"
        onClick={() => navigate('/assessment')}
      >
        End
      </Button>
      <Box className="Child">
        <Box className="progress-bar">
          <ProgressBar bgcolor="#6a1b9a" completed={progress} />
        </Box>

        <Box className="CardContainer">
          {currentQuestion ? (
            <Card className="Card">
              <CardContent>
                <Typography variant="h7" className='text-p'>
                  {currentQuestion.question}
                </Typography>
                {currentQuestion.ratings.map((rating, index) => {
                  const isSelected = String(answers[currentQuestion.question]) === String(rating);
                  return (
                    <Box key={rating} sx={{ mt: 1 }}>
                      <Button
                        className='rating-btn'
                        variant={isSelected ? 'contained' : 'outlined'}
                        onClick={() => handleAnswerChange(currentQuestion.question, rating)}
                        sx={{
                          width: '100%',
                          color: 'black',
                          fontWeight: 'bold',
                          borderColor: 'rgb(58, 6, 107)',
                          '&:hover': {
                            backgroundColor: 'rgb(58, 6, 107,0.3)',
                            borderColor: 'rgb(58, 6, 107)',
                          },
                        }}
                      >
                        {rating}
                      </Button>
                    </Box>
                  );
                })}
              </CardContent>
            </Card>
          ) : (
            <Typography>No question to display</Typography>
          )}

          {/* Display Submit button when on the last question */}
          {currentQuestionIndex === assessmentQuestions.length - 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button variant="contained" onClick={handleSubmit} className='submitbutton'>
                Submit
              </Button>
            </Box>
          )}

          {submissionStatus && (
            <Box sx={{
              mt: 2,
              p: 2,
              borderRadius: 2,
              backgroundColor: submissionStatus === 'Success' ? '#d4edda' : '#f8d7da',
              color: submissionStatus === 'Success' ? '#155724' : '#721c24',
              border: `1px solid ${submissionStatus === 'Success' ? '#c3e6cb' : '#f5c6cb'}`,
              maxWidth: 600,
              width: '100%',
              textAlign: 'center',
              margin: 'auto'
            }}>
              <Typography>
                {submissionStatus === 'Success' ? 'Test submitted successfully!' : 'There was an error submitting the test.'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AssessmentDetail;
