import React, { useState, useEffect } from 'react';
import { Pie, Radar, Bar, Line } from 'react-chartjs-2';
import { PieChart,Cell } from 'recharts';
import ApexChart from './ApexChart';
import 'chart.js/auto';
import './analysis.css';
import ApexCharts from 'react-apexcharts'; //Emoition Frequency Card
import ReactApexChart from 'react-apexcharts';
import ApexChart_1 from './ApexChart_1'; //For Assement data
import ApexChart_2 from './ApexChart_2';  //For personality Traits
 
 
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import ReactSpeedometer from 'react-d3-speedometer';
Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
 
 
const ChartsPage = ({ assessmentData = {}, combinedReport = {} }) => {
  // console.log(assessmentData)
  // console.log(combinedReport)
    const getDisplayName = (itemKey) => {
        switch (itemKey) {
          case 'anxiety_data':
            return 'Anxiety';
          case 'depression_data':
            return 'Depression'
          case 'relationship_data':
            return 'Relationship';
          case 'eating_disorder_data':
            return 'Eating Disorder';
          case 'intolerance_factor_a_data':
            return 'Intolerance Factor-A';
          case 'intolerance_factor_b_data':
            return 'Intolerance Factor-B';
          case 'polarity_disorder_data':
            return 'Polarity Disorder'
          case 'rumination_data':
            return 'Rummination'
          case 'personality_traits':
            return 'Personality Traits'
          case 'intolerance_data':
            return 'Intolerance Data'
          
          default:
            return itemKey.replace(/_/g, ' ').toUpperCase();
        }
      };
    
  
      
// Assume ApexChart_rum is a properly defined component
const ApexChart_rum = ({ series, options }) => (
  <ReactApexChart options={options} series={series} type="bar" height={350} />
);
 
const RuminationChart = () => {
  if (!assessmentData.rumination_data) return null;
 
  const Rummination_data = assessmentData.rumination_data;
  const factor1Score = parseFloat(Rummination_data.factor_1_rumination_score);
  const factor2Score = parseFloat(Rummination_data.factor_2_rumination_score);
  const factor1Severity = Rummination_data.rumination_factor_1_severity;
  const factor2Severity = Rummination_data.rumination_factor_2_severity;
  const explanation = Rummination_data.explanation;
 
  const series = [{
    name: 'Rumination Scores',
    data: [factor1Score, factor2Score]
  }];
 
  const options = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false // Hide the toolbar
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '50%',
      }
    },
    colors: [
      'rgba(82, 4, 85, 0.781)', // Color for Factor 1
      'rgba(140, 106, 148, 0.705)', // Color for Factor 2
    ],
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 0
    },
    grid: {
      row: {
        colors: ['#fff', '#f2f2f2']
      }
    },
    xaxis: {
      categories: ['Factor 1', 'Factor 2'],
      tickPlacement: 'on'
    },
    yaxis: {
      title: {
        text: 'Score',
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100]
      },
    },
    zoom: {
      enabled: false
    },
    legend: {
      show: false // Hide legend if not needed
    }
  };
 
  return (
    <div>
      <h3>Rumination Scores</h3>
      <div className='rumination-card'>
        <div>
          <p>{explanation}</p>
        </div>
        <ApexChart_rum series={series} options={options} />
        <p>Factor1 Severity: {factor1Severity} | Factor2 Severity: {factor2Severity}</p>
      </div>
    </div>
  );
};
 
 
/*const renderGaugeChart = () => {
  // Extract scores from combinedReport
  const positiveScore = combinedReport.average_score.positive;
  const negativeScore = combinedReport.average_score.negative;
  const neutralScore = combinedReport.average_score.neutral;
 
  // Convert scores from 0-10 to percentages
  const totalScore = positiveScore + negativeScore + neutralScore;
  const positivePercentage = (positiveScore / totalScore) * 100;
  const negativePercentage = (negativeScore / totalScore) * 100;
  const neutralPercentage = (neutralScore / totalScore) * 100;
 
  // Text explanation from combinedReport.average_score.explanation
  const text = combinedReport.average_score.explanation;
 
  // Radial bar chart configuration
  const series = [positivePercentage, negativePercentage, neutralPercentage];
 
  const options = {
    chart: {
      height: 390,
      //type: 'radialBar',
      type: ''
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          }
        },
        barLabels: {
          enabled: true,
          useSeriesColors: true,
          offsetX: -8,
          fontSize: '16px',
          formatter: function(seriesName, opts) {
            return seriesName + ": " + opts.w.globals.series[opts.seriesIndex].toFixed(1) + "%";
          },
        },
      }
    },
    colors: ['rgb(81, 13, 121)', 'rgb(223, 176, 255)', 'rgb(184, 98, 241)'], // Colors for positive, neutral, negative
    labels: ['Positive', 'Neutral', 'Negative'],
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };
 
  return (
    <div className='gauge-chart-wrapper'>
      <div className='gauge-chart-text'>
        <p>{text}</p>
      </div>
      <div className='gauge-chart-container'>
        <ReactApexChart
          options={options}
          series={series}
          type="radialBar"
          height={390}
        />
      </div>
    </div>
  );
};*/

const renderGaugeChart = () => {
  const positiveScore = combinedReport.average_score.positive;
  const neutralScore = combinedReport.average_score.neutral;
  const negativeScore = combinedReport.average_score.negative;

  const options = {
    chart: {
      type: 'polarArea',
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
      background: '#f5f5f5', // Custom background color
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 1000,
      },
    },
    labels: ['Positive', 'Neutral', 'Negative'],
    series: [positiveScore, neutralScore, negativeScore],
    colors: ['#6A0572', '#C899F8', '#B862F1'], // Consistent purple shades
    stroke: {
      width: 2,
      colors: ['#fff'],
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        gradientToColors: ['#A15DFF', '#6D00FF', '#8E2DE2'],
        stops: [0, 50, 100],
      },
      opacity: 0.9,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${Math.round(val)}%`;
      },
      style: {
        fontSize: '14px',
        colors: ['#4A148C'], // Using a dark shade of purple
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        colors: ['#6A0572', '#C899F8', '#B862F1'],
        fontSize: '14px',
      },
    },
    tooltip: {
      enabled: true,
      custom: ({ seriesIndex }) => {
        return `<div class="tooltip-custom" style="background: #ffffff; color: #4A148C; font-size: 14px; padding: 5px; border-radius: 4px;">
                  <span>${options.labels[seriesIndex]}</span>
                </div>`;
      },
    },
    dropShadow: {
      enabled: true,
      top: 3,
      left: 3,
      blur: 3,
      opacity: 0.2,
    },
    grid: {
      show: true,
      borderColor: '#e0e0e0',
      padding: {
        bottom: 0,
      },
    },
  };

  return (
    <div className='polar-area-chart-wrapper'>
      <div className='polar-area-chart-text'>
        <p>{combinedReport.average_score.explanation}</p>
      </div>
      <div className='polar-area-chart-container'>
        <ReactApexChart
          options={options}
          series={options.series}
          type="polarArea"
          height={350}
        />
      </div>
    </div>
  );
};

const renderEmotionFrequencyChart = () => {
  const explanation = combinedReport.emotion_frequency.explanation;
  const emotionCount = combinedReport.emotion_frequency.emotion_count;
 
  // Define an array of colors for the bars
  const colors = [
    'rgba(82, 4, 85, 0.781)', // Color for the first bar
    'rgb(120, 6, 146)', // Color for the second bar
    'rgb(221, 110, 247)', // Color for the third bar
    'rgb(190, 134, 210)', // Color for the fourth bar
    'rgba(197, 150, 199, 0.705)', // Color for the fifth bar
    'rgba(140, 106, 148, 0.705)', // Color for the sixth bar
    // Add more colors if you have more bars
  ];
 
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: false, // Removes the download icon
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: Object.keys(emotionCount),
    },
    colors: colors.slice(0, Object.keys(emotionCount).length), // Limit colors to the number of bars
  };
 
  const series = [{
    data: Object.values(emotionCount),
  }];
 
  return (
    <div className='Emotion_freq_card'>
      <div>
        <p>{explanation}</p>
      </div>
      <div className="bar-chart-container">
        <ApexCharts
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};


/*const renderFrequencyChart = () => {
  const explanation = combinedReport.frequency_result.explanation;
  const positiveCount = combinedReport.frequency_result.frequency_result.positive_count;
  const negativeCount = combinedReport.frequency_result.frequency_result.negative_count;
 
  const series = [
    {
      name: 'Frequency Count',
      data: [positiveCount, negativeCount],
    },
  ];
 
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false, // Disable the toolbar
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true, // Makes the chart horizontal
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: ['Positive', 'Negative'], // Labels for the y-axis
    },
    title: {
      text: 'Frequency Result Analysis',
      align: 'center',
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: '18px',
      },
    },
    fill: {
      colors: ['rgb(70, 3, 90)', 'rgba(158, 109, 214, 0.3)'],
    },
  };
 
  return (
    <div className='freq-chart'>
      <div>
        <p>{explanation}</p>
      </div>
      <div className="apex-chart-container">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
    </div>
  );
};*/
 
const renderFrequencyChart = () => {
  const explanation = combinedReport.frequency_result.explanation;
  const positiveCount = combinedReport.frequency_result.frequency_result.positive_count;
  const negativeCount = combinedReport.frequency_result.frequency_result.negative_count;

  const series = [
    {
      name: 'Frequency Count',
      data: [positiveCount, negativeCount],
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
      background: '#f5f5f5', // Custom background color
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 1000,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 12,
        horizontal: false,
        columnWidth: '50%',
        distributed: true,
        dataLabels: {
          position: 'top',
        },
        events: {
          click: (event, chartContext, { dataPointIndex }) => {
            alert(`Clicked on ${options.xaxis.categories[dataPointIndex]}`);
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        colors: ['#fff'],
      },
      formatter: (val) => `${val}`,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        gradientToColors: ['#A15DFF', '#6D00FF'],
        stops: [0, 100],
      },
    },
    xaxis: {
      categories: ['Positive', 'Negative'],
      labels: {
        style: {
          colors: ['#6A0572', '#C899F8'],
          fontSize: '14px',
        },
      },
    },
    title: {
      align: 'center',
      margin: 20,
      offsetY: 20,
      style: {
        fontSize: '20px',
        color: '#333',
      },
    },
    colors: ['#6A0572', '#C899F8'],
    grid: {
      show: true,
      borderColor: '#e0e0e0',
      row: {
        colors: ['#f9f9f9', 'transparent'],
        opacity: 0.5,
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} occurrences`,
      },
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        return `<div class="tooltip-custom">
                  <span>Count: ${w.globals.series[seriesIndex][dataPointIndex]}</span>
                </div>`;
      },
      theme: 'dark',
    },
    dropShadow: {
      enabled: true,
      top: 3,
      left: 3,
      blur: 3,
      opacity: 0.2,
    },
    annotations: {
      yaxis: [
        {
          y: positiveCount,
          borderColor: '#6A0572',
          label: {
            borderColor: '#6A0572',
            style: {
              color: '#fff',
              background: '#6A0572',
            },
            text: 'Positive Count',
          },
        },
      ],
    },
  };

  return (
    <div className='freq-chart'>
      <div>
        <p>{explanation}</p>
      </div>
      <div className="apex-chart-container">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
    </div>
  );
};






 /* const renderTrendChart = () => {
    const explanation = combinedReport.trend.explanation;
    const sentimentTrend = combinedReport.trend.sentiment_trend;
  
    // Round the values in sentiment_trend to 2 decimal places
    const roundedData = Object.values(sentimentTrend).map(value =>
      parseFloat(value.toFixed(2))
    );
  
    const series = [
      {
        name: 'Score Trend',
        data: roundedData,
      },
    ];
  
    const options = {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false, // Disable toolbar (home, download, zoom buttons)
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
        colors: ['#780692'], // Custom line color
        width: 2, // Adjust the width of the line
      },
      xaxis: {
        categories: Object.keys(sentimentTrend),
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm', // Adjust as needed for your data format
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          gradientToColors: ['#520455'], // Gradient end color
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 0.1,
          opacityTo: 0.2,
          stops: [0, 100],
        },
      },
      title: {
        text: 'Sentiment Trend Over Time',
        align: 'center',
        margin: 20,
        offsetY: 20,
        style: {
          fontSize: '18px',
        },
      },
      grid: {
        show: false, // Optionally hide gridlines if you want a cleaner look
      },
    };
  
    return (
      <div className='Trend-chart'>
        <div>
          <p>{explanation}</p>
        </div>
        <div className="apex-chart-container">
          <ReactApexChart options={options} series={series} type="area" height={350} />
        </div>
      </div>
    );
  };*/

  const renderTrendChart = () => {
    const explanation = combinedReport.trend.explanation;
    const sentimentTrend = combinedReport.trend.sentiment_trend;
  
    // Round the values in sentiment_trend to 2 decimal places
    const roundedData = Object.values(sentimentTrend).map(value =>
      parseFloat(value.toFixed(2))
    );
  
    const series = [
      {
        name: 'Score Trend',
        data: roundedData,
      },
    ];
  
    const options = {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: true,
          tools: {
            download: false, // Disable download button
          },
        },
        background: '#f5f5f5',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        colors: ['#6A0572'],
        width: 3,
      },
      xaxis: {
        categories: Object.keys(sentimentTrend),
        labels: {
          style: {
            colors: '#333',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#333',
          },
        },
      },
      tooltip: {
        enabled: true,
        theme: 'light',
        custom: ({ seriesIndex, dataPointIndex }) => {
          const date = Object.keys(sentimentTrend)[dataPointIndex];
          const value = Object.values(sentimentTrend)[dataPointIndex];
          return `<div class="tooltip-custom" style="background: #ffffff; color: #4A148C; font-size: 14px; padding: 10px; border-radius: 4px;">
                    <strong>Date:</strong> ${date}<br>
                    <strong>Score:</strong> ${value}
                  </div>`;
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          gradientToColors: ['#A15DFF'],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 0.3,
          opacityTo: 0.1,
          stops: [0, 100],
        },
      },
      title: {
        align: 'center',
        margin: 20,
        offsetY: 20,
        style: {
          fontSize: '18px',
          color: '#333',
        },
      },
      grid: {
        show: true,
        borderColor: '#e0e0e0',
        padding: {
          bottom: 0,
        },
      },
      dropShadow: {
        enabled: true,
        top: 3,
        left: 3,
        blur: 5,
        opacity: 0.3,
      },
      zoom: {
        enabled: true,
        type: 'x',
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '14px',
        labels: {
          colors: '#333',
        },
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              width: '100%',
            },
            title: {
              fontSize: '16px',
            },
          },
        },
      ],
    };
  
    return (
      <div className='trend-chart'>
        <div>
          <p>{explanation}</p>
        </div>
        <div className="apex-chart-container">
          <ReactApexChart options={options} series={series} type="area" height={350} />
        </div>
      </div>
    );
  };
  
  
  
   
const renderBigFiveTable = () => {
  if (!assessmentData.personality_traits) return null;
 
  const bigFiveData = assessmentData.personality_traits;
 
  const formatText = (text) => {
    return text.split('_').join(' ');
  };
 
  // Convert scores to percentages
  const scores = [
    bigFiveData.agreeableness_score * 20,
    bigFiveData.conscientiousness_score * 20,
    bigFiveData.neuroticism_score * 20,
    bigFiveData.extraversion_score * 20,
    bigFiveData.openness_to_experience_score * 20,
  ];
 
  /*const labels = [
    'Agreeableness',
    'Conscientiousness',
    'Neuroticism',
    'Extraversion',
    'Openness to Experience',
  ];*/
  const labels = [
  'Agreeableness',
  'Conscientiousness',
  'Emotional Resilience (Neuroticism)', // Softens "Neuroticism"
  'Expressiveness (Extraversion)',      // More relatable than "Extraversion"
  'Openness to New Experiences',        // Makes "Openness" more descriptive
];
 
/*  return (
    <div className="personality-card">
    
     
      <p>{bigFiveData.explanation}</p>
        <ApexChart_2 scores={scores} labels={labels} />
        {labels.map((label, index) => (
          <p key={index}>
            //<strong>{label} Severity Level:</strong> {formatText(bigFiveData[`${labels[index].toLowerCase().replace(/ /g, '_')}_severity_level`])}
			<strong>{label} Level:</strong> {formatText(bigFiveData[`${labels[index].toLowerCase().replace(/ /g, '_')}_severity_level`])}
          </p>
        ))}
      
     
    </div>
  );
  */
return (
  <div className="personality-card">
    <p>{bigFiveData.explanation}</p>
    <ApexChart_2 scores={scores} labels={labels} />

    {labels.map((label, index) => (
      <p key={index}>
        <strong>{label} Level:</strong>{" "}
        {formatText(bigFiveData?.[`${labels[index].toLowerCase().replace(/ /g, "_")}_severity_level`] || "")}
      </p>
    ))}

    {/* Disclaimer for Self-Reflection */}
    <p className="disclaimer">
      *These insights are designed for self-reflection and personal awareness. They are not intended as a diagnostic or psychological evaluation.*
    </p>
  </div>
);


};
 
 
{/*  return (
    <div className='main-card'>
    <div className='all-cards'>
      {combinedReport && (
        <>
          <div className="row">
            {combinedReport.average_score !== 0 && (
              <div className="card gauge-chart">
                <h2>Average Score</h2>
                {renderGaugeChart()}
              </div>
            )}
  
            {Object.keys(combinedReport.frequency_result).length > 0 && (
              <div className="card bar-chart">
                <h2>Frequency Result</h2>
                {renderFrequencyChart()}
              </div>
            )}
          </div>
          <div className='row'>
            {Object.keys(combinedReport.trend).length > 0 && (
              <div className="card trend-chart">
                <h2>Sentiment Analysis Trend</h2>
                {renderTrendChart()}
              </div>
            )}
  
            {combinedReport.emotion_frequency && (
              <div className="card emotion_frequency">
                <h2>Emotion Frequency</h2>
                {renderEmotionFrequencyChart()}
              </div>
            )}
          </div>
        </>
      )}
  
      {assessmentData && (
        <>
          <div className="title-container">
            Assessment Analysis
          </div>
          <div className="card chart-container">
            <ApexChart_1 assessmentData={assessmentData} />
          </div>
          
          <div className='row'>
            {assessmentData.rumination_data?.assessment_exist && (
              <div className='card rum'>
                {RuminationChart()}
              </div>
            )}
            
            {assessmentData.personality_traits && (
              <div className='card pers'>
                <h2>Personality Traits</h2>
                {renderBigFiveTable()}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  </div>
  
  );
}; */}
 
 
 
 
return (
  <div className='main-card'>
    <div className='all-cards'>
      {combinedReport && (
        <>
          <div className="row">
            {combinedReport.average_score !== 0 && (
              <div className="card gauge-chart">
                <h2>Personal Mood Trends</h2>
                {renderGaugeChart()}
              </div>
            )}
  
            {Object.keys(combinedReport.trend).length > 0 && (
              <div className="card trend-chart">
                <h2>Sentiment Analysis Trend</h2>
                {renderTrendChart()}
              </div>
            )}
          </div>
          <div className='row'>
            {Object.keys(combinedReport.frequency_result).length > 0 && (
              <div className="card bar-chart">
                <h2>Frequency Result</h2>
                {renderFrequencyChart()}
              </div>
            )}
  
            {combinedReport.emotion_frequency && (
              <div className="card emotion_frequency">
                <h2>Mood & Emotion Levels</h2>
                {renderEmotionFrequencyChart()}
            </div>
          )}
        </div>
      </>
    )}
 
    {assessmentData && (
      <>
        <div className="title-container">
          Assessment Analysis
        </div>
        <div className="card chart-container">
          <ApexChart_1 assessmentData={assessmentData} />
        </div>
        
        <div className='row'>
          {assessmentData.rumination_data?.assessment_exist && (
            <div className='card rum'>
              {RuminationChart()}
            </div>
          )}
          
          {assessmentData.personality_traits && (
            <div className='card pers'>
              <h2>Personality Traits</h2>
              {renderBigFiveTable()}
            </div>
          )}
        </div>
      </>
    )}
  </div>
</div>
 
);
};
 
 
 
 
 
export default ChartsPage;